const ajaxSetup = () => {
    $.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},
		error: function (res, status, error) {
			if (res.status == 419) {
				toastr.error('Vui lòng tải lại trang');
				setTimeout(function reloadError(){
					location.reload();
				}, 1000);
			}else {
				toastr.error('Có lỗi xảy ra, mã: ' + res.status);
			}
		}
	});
}

const menuMobile = () => {
	$(".menu-mobile").click(function(){
		if ($('#input-menu').is(":checked")){
			$(".menu-bg").addClass("menu-bg-show");
		}else{
			$(".menu-bg").removeClass("menu-bg-show");
		}
	});
}

const headerFixed = () => {
	$(window).bind('scroll', function () {
        if ($(window).scrollTop() > 140) {
            $('#single-head').addClass('head-fixed');
			$('.row-head').addClass('is-fixed');
        } else {
            $('#single-head').removeClass('head-fixed');
			$('.row-head').removeClass('is-fixed');
        }
    });
}


export const init = {
    ajaxSetup,
    menuMobile,
	headerFixed
}