window._ = require('lodash');
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    
} catch (e) {}

window.Swal = require('sweetalert2');
window.toastr = require('toastr');

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { init } from "./helpers/init";

init.ajaxSetup();
init.menuMobile();
init.headerFixed();